import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import QuickGuideImgSource from 'images/shipkoo-warehouse-and-fulfillment.png';
import TimeSavingImgSource from 'images/time-effective.png';
import OrderFulfillmentImgSource from 'images/blog-images/ecommerce-fulfillment-img.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query AQuickGuideBlogImg {
      file(relativePath: { eq: "shipkoo-warehouse-and-fulfillment-cover.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="shipkoo warehouse and fulfillment cover"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Choosing the Best eCommerce Shipping Solution | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-choosing-the-best" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "A successful online business starts with choosing the best ecommerce platform for you. This is the most effective guide in choosing what is the best for you.",
            },
            {
              property: `og:title`,
              // content: "Choosing the Best eCommerce Shipping Solution | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-choosing-the-best" }),
            },
            {
              property: `og:description`,
              content: "A successful online business starts with choosing the best ecommerce platform for you. This is the most effective guide in choosing what is the best for you.",
            },
            {
              property: `og:image`,
              content: QuickGuideImgSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="a-quick-guide-for-choosing-the-best-ecommerce-shipping-solutions"
        title="A Quick Guide for Choosing the Best eCommerce Shipping Solutions"
        date="2020-04-22"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <Image src={QuickGuideImgSource} className="no-top" alt="shipkoo warehouse and fulfillment"/>

            <h5>A Quick Guide for Choosing the Best eCommerce Shipping Solutions</h5>
            <p>
              In this fast-paced world where time is of the essence, it is crucial how, when, and where your product reaches the customer when running an online business. eCommerce shipping companies are doing their best to offer customers smooth, fast, and easily manageable delivery services. Businesses looking to efficiently reach their customers and build a good relationship to ensure long term trade, need the right eCommerce shipping solution to achieve this goal. It is considered an impactful step to be taken in order to grow a business online.
            </p>

            <p>
              When operating in a highly competitive business field, it is of the utmost importance to work with the best logistics solution provider. To keep up with the competition, you need to have a well-planned shipping solution to ensure the success of the business.
            </p>

            <h5>Use of Time Saving Features</h5>
            
            <Image src={TimeSavingImgSource} alt="time effective"/>

            <p>
              An excellent shipping solution offers features that answer your needs. Having all of your orders in one place allows you to get a lot more work done, and lets you do it more effectively. These are some features to look for.
            </p>
            
            <p>
              <span>
                <strong>
                  Shipping Rate Calculator
                </strong>
              </span>
            </p>

            <p>
              A <a href="https://app.shipkoo.com/calculator" target="_blank" rel="noreferrer">shipping rate calculator</a> is needed whenever you need to have an estimate of what it will cost you to transport an item. It is determined using the package weight and dimension.
            </p>

            <p>
              <strong>
                Shipment Tracking
              </strong>
            </p>

            <p>
              You want to make sure everything is running smoothly while the package is in transit. Real-time updates can be accessed using a mobile or web app or via email.
            </p>

            <p>
              <strong>
                Platform Integrations
              </strong>
            </p>

            <p>
              <a href="/ecommerce-store-integration/">Integration between platforms</a> allows data to flow smoothly between the systems. It enables you to manage inventory, orders, and shipments easily.
            </p>

            <h5>Automate eCommerce Order Fulfillment</h5>

            <p>
              It is extremely important to work with the best logistics solution provider when you are operating in a highly competitive business environment. New challenges may occur on a daily basis and it can therefore be very useful to automate your order fulfillment.
            </p>

            <p>
              Look for a trusted logistics company that offers warehousing and one-stop solutions for eCommerce business. Hand the products over to them and let them take care of everything, so that you can focus on growing your business. Your logistics partner can process your orders, inspect them for damage, and make them ready for shipment to your customers.
            </p>

            <Image src={OrderFulfillmentImgSource} alt="Ecommerce Fulfillment"/>

            <h5>Choosing Shipkoo as your One Stop Solution eCommerce Shipping Platform</h5>

            <p>
              Having a reliable system that ensures a fast, cost-saving system which can ensure customer value is vital for a successful business. <strong>Shipkoo</strong> handles everything starting from the manufacturer, all the way to the end-customer. With advanced technology at our disposal, we can ensure cost-saving express delivery and automated eCommerce order fulfillment. We take care of all your logistics so that you don’t have to worry about it.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;